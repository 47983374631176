import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    margin: 30,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const PressContent = [
    {
      imageName: "root-logo.jpg",
      title: `How Stephan Built His Facebook Banker Chatbot`,
      outletName: "Root",
      link: "https://root.co.za/blog/how-stephan-built-his-facebook-messenger-banking-bot",
    },
    {
      imageName: "Vodacom-Business-logo.jpg",
      title: `Stop Assuming. Start Testing.`,
      outletName: "Vodacom Business",
      link : "https://www.vodacombusiness.co.za/cs/groups/public/documents/document/fast-forward-series-catalogue.pdf#page=3"
    },
    {
      imageName: "ionofm-logo.jpg",
      title: `Vodacom Fast Forward Series : Growth Hacking`,
      outletName: "iono.fm",
      link: "https://iono.fm/e/767271"
    },
    {
      imageName: "CC-logo.jpg",
      title: `Create the right environment for innovation to flourish.`,
      outletName: "The Cape Chamber’s",
      link: "https://medium.com/@kellymillard/create-the-right-environment-for-innovation-to-flourish-and-watch-your-business-thrive-in-future-b88ca2f5bd22",
    },
    {
      imageName: "FastCompany.webp",
      title: `For taking the fear out of new technology`,
      outletName: "FastCompany SA",
      link: "https://issuu.com/fastcompanysa/docs/fc_dec-jan_2017_issue_22/54",
    },
    {
      imageName: "brainstom-mag-logo.jpg",
      title: `There be monsters...`,
      outletName: "Brainstorm Magazine",
      link: "http://www.brainstormmag.co.za/innovation/12699-there-be-monsters",
    },
  ];

  return (
    <div className="testimonial-wrapper">
      <Slider {...settings}>
        {PressContent.map((val, i) => (
          <div key={i}>
            <a href={val.link} rel="noreferrer" target="_blank">
              <div className="testimonial-01 media">
                <div className="avatar">
                  <img
                    src={`img/press/${val.imageName}`}
                    alt="review comments"
                  ></img>
                </div>
                <div className="media-body">
                  <h5>{val.title}</h5>
                  <h6>{val.outletName}</h6>
                  <div className="readmore">Read More</div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
}
