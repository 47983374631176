import React from "react";

const BrandContnet = [
  {
    img: "absa",
    brandName: "absa",
  },
  {
    img: "MortgageMe",
    brandName: "MortgageMe",
  },
  {
    img: "Ogilvy",
    brandName: "Ogilvy",
  },
  {
    img: "Samsung",
    brandName: "Samsung",
  },
  {
    img: "VodacomBusiness",
    brandName: "VodacomBusiness",
  },
  {
    img: "Mcdonalds",
    brandName: "Mcdonald`s",
  },
  
];

const Brands = () => {
  return (
    <>
      <div className="row">
        {BrandContnet.map((val, i) => (
          <div className="col-lg-2 m-15px-tb" key={i}>
            <div className="feature-box-02 d-flex align-items-center">
              <div className="brand">
                <img src={`img/brand/${val.img}.webp`} alt={val.brandName} />
              </div>
            </div>
          </div>
          // End .col
        ))}
      </div>
      {/* End .row */}
    </>
  );
};

export default Brands;
