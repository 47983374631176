import React from "react";
import Skills from "../skills/Skills";

const resumeContent = [
  {
    jobPosition: `Founder`,
    jobType: `CEO`,
    jobDuration: `Jul 2021 - Present`,
    timeDuraton: `Part Time`,
    compnayName: "HackAware",
    jobDescription: `We help organisations like yours find your weaknesses before hackers do. We regularly scan your development environments and provide a helping hand to ensure the best security practices by upskilling and certifying your development teams and boosting employee awareness. We are here to help with all your InfoSec requirements and offer assistance with POPI and GDPR compliance.`,
  },
  {
    jobPosition: `Board Member`,
    jobType: `Membership Chair`,
    jobDuration: `Jun 2020 - Present`,
    timeDuraton: `Part Time`,
    compnayName: "EO Accelerator",
    jobDescription: `Accelerator, an Entrepreneurs’ Organization program, is the catalyst that enables first-stage entrepreneurs to catapult your business to the next level. Our mission is to empower you with the tools you need to grow your business to more than US$1 million in sales and provide you with the skills to make yourself a better entrepreneur and leader.`,
  },
  {
    jobPosition: `Co-Founder`,
    jobType: `Co-Founder`,
    jobDuration: `Aug 2019 - Present`,
    timeDuraton: `Part Time`,
    compnayName: "Lighthouse",
    jobDescription: `Lighthouse is the only parental control solution that uses AI that assess and block pornography in real-time, before your child has a chance to see it.
    Let your kids be kids for longer and stop inappropriate videos and imagery at the source.`,
  },
  {
    jobPosition: `Co-Founder`,
    jobType: `Co-founder & CTO`,
    jobDuration: `Aug 2015 - Present`,
    timeDuraton: `Full Time`,
    compnayName: "WeAreMonsters",
    jobDescription: `An innovative think-tank that turns problems into ideas and ideas into products. We are a human-centred business, we champion the customer, function as your remote team and pride ourselves on our long-standing partnerships. Starting from problem validation, we transform your idea into something tangible in a matter of weeks and help you build your product into a successful business.`,
  },
  {
    jobPosition: `Technical Director`,
    jobType: ``,
    jobDuration: `Feb 2012 - Jul 2015`,
    timeDuraton: `Full Time`,
    compnayName: "Digitas Liquorice",
    jobDescription: `Digitas Liquorice is a connected experience agency with a focus on data-led insights to enhance the Customer Experience. We specialise in 5 core digital capabilities to deliver Data & Analysis, Strategy, Creative, Media and Technology. In 2014, Liquorice was acquired by Digitas, the world’s largest digital network employing over 100 people across our 3 offices in South Africa.`,
  },
];

const educatonContent = [
  {
    passingYear: "2019",
    degreeTitle: "Unlocking Creativity",
    instituteName: "IDEO U",
  },
  {
    passingYear: "2013",
    degreeTitle: "Advanced White Hat Hacking And Penetration Testing",
    instituteName: "Infinite Skills",
  },
  {
    passingYear: "2013",
    degreeTitle: "Certified ScrumMaster",
    instituteName: "Scrum Alliance",
  },
  {
    passingYear: "2004-2007",
    degreeTitle: "Bsc. in Computer Science",
    instituteName: "Belgium Campus",
  },
];

const Resume = () => {
  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <h3>Experience.</h3>
          </div>
          {/* End title */}
          <div className="resume-box">
            {resumeContent.map((val, i) => (
              <div className="resume-row" key={i}>
                <div className="row">
                  <div className="col-md-4 col-xl-3">
                    <div className="rb-left">
                      <h6>{val.jobPosition}</h6>
                      <label>{val.jobType}</label>
                      <p>{val.jobDuration}</p>
                      {/* <div className="rb-time">{val.timeDuraton}</div> */}
                    </div>
                  </div>
                  <div className="col-md-8 col-xl-9">
                    <div className="rb-right">
                      <h6>{val.compnayName}</h6>
                      <p>{val.jobDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
              // End resume-row
            ))}
          </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}
          
          <div className="title">
            <h3>Education & Skills</h3>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-4 m-15px-tb">
              <ul className="aducation-box">
                {educatonContent.map((val, i) => (
                  <li key={i}>
                    <span>{val.passingYear}</span>
                    <h6>{val.degreeTitle} </h6>
                    <p>{val.instituteName}</p>
                  </li>
                ))}
              </ul>
            </div>
            {/* End .col */}

            <div className="col-lg-7 ml-auto m-15px-tb">
              <Skills />
            </div>
            {/* End .col */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Resume;
