import React from "react";

const ServiceContent = [
  {
    class: "feature-box-01",
    icon: "icon-puzzle",
    title: "Innovation Consultant",
    descriptions: `Innovation Consultant best showcases my holistic approach. This type of workshop-minded offer can span from understanding where your team or organization goes next to delving deeper into your competitive edge or materializing a strategic purpose.`,
  },
  {
    class: "feature-box-01",
    icon: "icon-tools-2",
    title: "Software Development",
    descriptions: `Finding unique ways to make something happen through software development pulls at the core of my love for coding and innovation. Over 15 years of working on everything from simple websites to complex enterprise solutions, I am looking forward to getting stuck in.`,
  },
  {
    class: "feature-box-01",
    icon: "icon-refresh",
    title: "Product Ideation",
    descriptions: `The interactive, adventurous, and exploratory process of developing a product idea is deeply engaging. In the last six years of bringing product big ideas into play, I have developed a synergistic approach.    `,
  },
  {
    class: "feature-box-03",
    icon: "icon-strategy",
    title: "Solutions Architect",
    descriptions: `Putting the pieces of the puzzle together lights a fire in me. The greater the challenge, the more my curiosity is peaked. My approach is to build a synchronous plan so that your team remains efficient while tackling the challenges ahead as they arise.`,
  },
  {
    class: "feature-box-03",
    icon: "icon-upload",
    title: "Information Security",
    descriptions: `Cybersecurity is a growing concern, it is multi-faceted and challenging. I am here to find weak points (you know, before the bad guys do) and to increase your team’s knowledge. It’s crucial to improve your tools and awareness.`,
  },
  {
    class: "feature-box-03",
    icon: "icon-megaphone",
    title: "Keynote Speaker",
    descriptions: `I make tech topics approachable, personable and fun! Should we delve into how to test out your tech idea? How to get your MVP to market? Security awareness?... Let’s get into it!    `,
  },
];
export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div className="col-md-6 col-lg-4 my-3" key={i}>
            <div className={`${val.class}`}>
              <div className="icon">
                <i className={`icon ${val.icon}`}></i>
              </div>
              <div className="feature-content">
                <h5>{val.title}</h5>
                <p>{val.descriptions}</p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
