import React from "react";

const AwardContnet = [
  {
    img: "FastCompany",
    awardName: "FastCompany",
    awardFor: "Top 25 world's most innovative companies",
    year: 2016
  },
  {
    img: "PriceCheck",
    awardName: "Tech & E-Commerce Awards",
    awardFor: "Employer of the year runner-up",
    year: 2016
  },
  {
    img: "CreativeCircle",
    awardName: "Creative Circle Awards",
    awardFor: "Castle light ‘Castle Twerk Truck’",
    year: 2014
  },
];

const Awards = () => {
  return (
    <>
      <div className="row">
        {AwardContnet.map((val, i) => (
          <div className="col-lg-4 m-15px-tb" key={i}>
            <div className="feature-box-02 d-flex align-items-center">
              <div className="icon">
                <img src={`img/award/${val.img}.webp`} alt={val.awardName} />
              </div>
              <div className="media-body">
                <h6>{val.awardName}</h6>
                <p>{val.awardFor}</p>
                <small className="text-muted">{val.year}</small>
              </div>
            </div>
          </div>
          // End .col
        ))}
      </div>
      {/* End .row */}
    </>
  );
};

export default Awards;
