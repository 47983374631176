import React from "react";
import TextLoop from "react-text-loop";
import Social from "../Social";
import Press from "../press/Press";
import Services from "../service/Service";
import Awards from "../award/Awards";
import Brands from "../brand/Brands";

const AboutThree = () => {
  return (
    <>
      <section id="about" className="section theme-light dark-bg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-6 col-lg-4">
              <div className="about-me">
                <div className="img">
                  <div className="img-in">
                    <img src="img/about/Stephan-Steynfaardt-about.jpeg" alt="Stephan Steynfaardt" />
                  </div>
                  <Social />
                  {/* End social icon */}
                </div>
                {/* End img */}
                <div className="info">
                <TextLoop>
                  <p className="loop-text">Innovation Consultant</p>
                  <p className="loop-text">Software Developer</p>
                  <p className="loop-text">Product Ideator</p>
                  </TextLoop>{" "}
                  
                  <h3>Stephan Steynfaardt</h3>
                </div>
                {/* End info */}
              </div>
              {/* End about-me */}
            </div>
            {/* End col */}

            <div className="col-lg-7 ml-auto">
              <div className="about-info">
                <div className="title">
                  <h3>Biography</h3>
                </div>
                <div className="about-text">
                  <p>
                  At a young age, I discovered a love for taking things apart and reconstructing them myself, <em>mostly</em> with success, which led to a passion for creative problem solving and an excitement for challenges.
                  The tech industry is where all this has come together. My childhood affinity led me to a Bachelor’s Degree in Computer Science and over 15 years in the industry.
                  </p>
                  <p>
                  From developer to CTO, I have taken on a steep learning curve. 
                  After launching multiple start-ups, consulting and facilitating in big corporations, 
                  and problem-solving for key investors and stakeholders (not to mention for my own start up), 
                  I possess a holistic approach to the tech field, a unique strategy for tackling its challenges and a devotion to its exciting future.
                  </p>
                </div>
                <div className="info-list">
                  <div className="row">
                    <div className="col-sm-12">
                      <a className="px-btn px-btn-theme mr-4" href="#contactus">Let’s Talk</a>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            {/* End col */}
          </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}
          <div className="title">
            <h3>What I do?</h3>
          </div>
          <Services />
          {/* End .row */}

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>Brands & Clients.</h3>
          </div>
          <Brands />

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          
          <div className="title">
            <h3>Awards.</h3>
          </div>
          <Awards />
          {/* End Awards */}

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title" id="press">
            <h3>Press.</h3>
          </div>
          <Press />
          {/* End Press */}
        </div>
      </section>
    </>
  );
};

export default AboutThree;
