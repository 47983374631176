import React, { useState } from "react";
import { useForm, ValidationError } from '@formspree/react';
import Fade from "react-reveal/Fade";

function ContactForm() {
  const [updatedSubmitButton, setUpdatedSubmitButton] = useState(false);
  const [submitText, setSubmitText] = useState('Send Message');
  const [state, handleSubmit] = useForm('xgerjroe');
  //successful form submition
  if (state.succeeded) {
    return (<>
      <Fade bottom>
        <h4>
          Message received, loud and clear!
        </h4>
      </Fade>
      <div className="box_inner">
        <div className="scrollable">
          <div className="blog-grid">
            <article className="article">
              <div className="article-title">
              </div>
              <Fade bottom>
              <div className="article-content">
                <p>
                  Thank you for getting in touch, your message was sent successfully.<br/>
                  If your inquiry is urgent, please get in touch using the telephone number on the left. 
                </p>
                <p>
                  Otherwise, I will reply by email as soon as possible.
                </p>
                <p>
                  Talk to you soon, Stephan
                </p>
              </div>
              </Fade>
              
            </article>
            
          </div>
        </div>
      </div>
    </>);
    
  }
  else if(state.submitting){
    if(!updatedSubmitButton){
      setUpdatedSubmitButton(true);
      document.getElementById("contactForm").style.opacity = "0.5";
      setSubmitText('Sending..');
    }
  }

  return (
    <>
      <h4>Don't be a stranger, say hi</h4>
      <form id="contactForm" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            {/*<button onClick={toggleContactModal}>Test</button>*/}
            <input type="text" name="_gotcha" style={{display:'none'}} />
            <div className="form-group mb-3">
            <label htmlFor="full_name">
              <span>Full Name:</span>
            </label>  
              <input
                id="full_name"
                name="full_name"
                type="text"
                className="form-control theme-light"
                placeholder="Full name"
                required
              />
              <ValidationError 
                prefix="Full Name" 
                field="full_name"
                errors={state.errors}
              />
            
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <label htmlFor="mobile">
              <span>Mobile Number:</span>
            </label>  
              <input
                type="text"
                id="mobile"
                name="mobile"
                className="form-control theme-light"
                placeholder="Mobile number"
                required
              />
              <ValidationError 
                prefix="Mobile number" 
                field="mobile"
                errors={state.errors}
              /><div className="form-group mb-3">
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-12">
            <div className="form-group mb-3">
              <label htmlFor="email">
                <span>Email Address:</span>
              </label>  
              <input
                type="email"
                id="email"
                name="email"
                className="form-control theme-light"
                placeholder="Email address"
                required
              />
              <ValidationError 
                prefix="Email" 
                field="email"
                errors={state.errors}
              />
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <div className="form-group mb-3">
              <label htmlFor="message">
                <span>Message:</span>
              </label>  
              <textarea
                rows="4"
                id="message"
                name="message"
                className="form-control theme-light"
                placeholder="Type message"
                required
              ></textarea>
              <ValidationError 
                prefix="Message" 
                field="message"
                errors={state.errors}
              />            
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="btn-bar">
              <button type="submit" disabled={state.submitting} className="px-btn px-btn-white">{submitText}</button>
            </div>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
      {/* End .Form */}
    </>
  );
}

export default ContactForm