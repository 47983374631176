import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { FiPlus } from "react-icons/fi";
import Masonry from "react-masonry-css";

const options = {
  settings: {
    overlayColor: "rgba(23, 144, 195, 0.4)",
    transitionSpeed: 800,
    disablePanzoom: true,
    boxShadow: '0 0 0.85rem rgba(128, 128, 128, 0.5)',
  },
  buttons: {  
    showDownloadButton: false,
    showFullscreenButton: false,
    showThumbnailsButton: false,
    showAutoplayButton: false,
  },
  caption: {
    captionColor: "#FFF",
    captionFontWeight: "500",
    captionTextTransform: "uppercase",
  }
};

// Create an object with the callbacks that you want to use
const callbacks = {
  onLightboxOpened: () => {document.getElementsByClassName('main-left').item(0).classList.add('blur')},
  onLightboxClosed: () => {document.getElementsByClassName('main-left').item(0).classList.remove('blur')},
};

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};

const Portfolio = () => {
  return (
    <SimpleReactLightbox>
      <div className="portfolio-filter-01">
        <Tabs>
          <TabList className="filter d-flex flex-wrap justify-content-start d-none">
            <Tab>All</Tab>
          </TabList>
          {/* End tablist */}          
          <SRLWrapper options={options} callbacks={callbacks}>
            <TabPanel>
              <div className="portfolio-content ">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                        <h5>Lighthouse</h5>
                        <span>{"Ideation, Product development, iOS & Android App"}</span>
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/Lighthouse-website.jpeg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/Lighthouse-website.jpeg"
                          alt={"Ideation, Product development, iOS & Android App"}
                        />
                      </a>
                      {/* End gallery link */}

                      <div className="portfolio-icon">
                        <FiPlus />
                      </div>
                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                        <h5>Mcdonald's - Smurf Yourself</h5>
                        <span>Chatbot, Software Development, Solutions Architect</span>
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/mcdonalds-smurf-yourself.jpeg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/mcdonalds-smurf-yourself.jpeg"
                          alt="Chatbot, Software Development, Solutions Architect"
                        />
                      </a>
                      {/* End gallery link */}

                      <div className="portfolio-icon">
                        <FiPlus />
                      </div>
                      {/* End .portfolio-icon */}
                    </div>
                  </div>{/* grid item  */}
                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                        <h5>CarZar</h5>
                        <span>Product Ideation, Solutions Architect, Software Development</span>
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/CarZar-website.jpeg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/CarZar-website.jpeg"
                          alt="Product Ideation, Solutions Architect, Software Development"
                        />
                      </a>
                      {/* End gallery link */}

                      <div className="portfolio-icon">
                        <FiPlus />
                      </div>
                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                        <h5>Momo</h5>
                        <span>Chatbot, Software Development, Solutions Architect</span>
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/momo-chat-bot.gif"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/momo-chat-bot.gif"
                          alt="Chatbot, Software Development, Solutions Architect"
                        />
                      </a>
                      {/* End gallery link */}

                      <div className="portfolio-icon">
                        <FiPlus />
                      </div>
                      {/* End .portfolio-icon */}
                    </div>
                  </div>{/* grid item  */}
                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                        <h5>MortgageMe</h5>
                        <span>Product Ideation, Solutions Architect</span>
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/MortgageMe-website.jpeg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/MortgageMe-website.jpeg"
                          alt="Product Ideation, Solutions Architect"
                        />
                      </a>
                      {/* End gallery link */}

                      <div className="portfolio-icon">
                        <FiPlus />
                      </div>
                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                        <h5>WeAreMonsters</h5>
                        <span>Founder, Product Ideation, Solutions Architect</span>
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/WeAreMonsters-website.jpeg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/WeAreMonsters-website.jpeg"
                          alt="Founder, Product Ideation, Solutions Architect"
                        />
                      </a>
                      {/* End gallery link */}

                      <div className="portfolio-icon">
                        <FiPlus />
                      </div>
                      {/* End .portfolio-icon */}
                    </div>
                  </div>{/* grid item  */}
                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                        <h5>Cloudsafe</h5>
                        <span>Product Ideation, Solutions Architect, Software Development</span>
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/Cloudsafe-website.jpeg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/Cloudsafe-website.jpeg"
                          alt="Product Ideation, Solutions Architect, Software Development"
                        />
                      </a>
                      {/* End gallery link */}

                      <div className="portfolio-icon">
                        <FiPlus />
                      </div>
                      {/* End .portfolio-icon */}
                    </div>
                  </div>{/* grid item  */}
                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                        <h5>SpamDoctor</h5>
                        <span>Product Ideation, Solutions Architect, Software Development</span>
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/Spamdoctor-website.jpeg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/Spamdoctor-website.jpeg"
                          alt="Product Ideation, Solutions Architect, Software Development"
                        />
                      </a>{/* End gallery link */}
                      <div className="portfolio-icon">
                        <FiPlus />
                      </div>
                      {/* End .portfolio-icon */}
                    </div>
                  </div>{/* grid item  */}
                </Masonry>
              </div>
              {/* End list wrapper */}
            </TabPanel>
            </SRLWrapper>
          {/* End tabpanel */}
        </Tabs>
      </div>
    </SimpleReactLightbox>
    
  );
};

export default Portfolio;
