import React from "react";
import TextLoop from "react-text-loop";
import Fade from "react-reveal/Fade";

const sliderContent = {
  name: "Stephan Steynfaardt",
  description: `I love dynamically engaging with others around technology, simplifying the complex, and creating an approachable and fun environment. 
  I facilitate a trusting space to explore together. 
  I foster an adventurous spirit, keeping everyone engaged when bringing your product ideas to life.`,
  btnText: "HIRE ME",
  btnText2: "My Work",
};

const SliderFive = () => {
  return (
    <>
      {/*  Home Banner */}
      <section
        id="home"
        className="home-banner home-banner-two slider-four bg-normal"
      >
        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-md-6 order-2 order-md-1">
              <div className="type-box">
                <Fade bottom>
                  <h6>Hello there... I'm</h6>
                  <h1 className="font-alt">{sliderContent.name}</h1>
                  <TextLoop className="textSlider">
                    <p className="loop-text lead">Innovation consultant</p>
                    <p className="loop-text lead">Software Developer</p>
                    <p className="loop-text lead">Product Ideator</p>
                    <p className="loop-text lead">Solutions Architect</p>
                    <p className="loop-text lead">Keynote Speaker</p>
                    <p className="loop-text lead">Ethical Hacker</p>
                  </TextLoop>
                  <p className="desc">{sliderContent.description}</p>
                  <div className="d-flex btn-wrapper">
                    <a className="px-btn px-btn-theme mr-4" href="#work">
                      {sliderContent.btnText2}
                    </a>
                    <a className="px-btn btn-outline " href="#contactus">
                      {sliderContent.btnText}
                    </a>
                  </div>
                </Fade>
              </div>
            </div>
            {/* End .col */}
            <div className="col-md-6 order-1 order-md-2">
              <img src="img/slider/Stephan-Steynfaardt.png" alt="Stephan Steynfaardt" />
            </div>
          </div>
        </div>
        {/* End Container*/}
        <div className="go-to go-to-next">
          <a href="#about">
            <span></span>
          </a>
        </div>
      </section>

      {/* End Home Banner  */}
    </>
  );
};

export default SliderFive;
